<template>
  <div>
    <div class="router-view-container">
      <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

      <main class="space-y-10">
        <!-- 正在使用的主題 -->
        <section class="grid grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-y-0 md:gap-x-10 items-center">
          <div v-if="is_API_loaded" class="flex flex-col">
            <h1 class="title">正在使用的主題</h1>
            <p class="mb-4">此為您的網站正在使用中的主題，點擊編輯佈景主題來調整網站外觀。</p>
            <vs-button @click="editTheme(activatingTheme.shno)" size="large" class="font-medium self-end">編輯佈景主題</vs-button>
          </div>
          <!-- 骨架屏 -->
          <div v-else class="flex flex-col">
            <!-- <h1 class="">正在使用的主題</h1> -->
            <skeleton width="120px" height="25px" class="title" />
            <div class="mb-4 space-y-1">
              <skeleton height="25px" />
              <skeleton height="25px" />
              <skeleton height="25px" />
            </div>
            <skeleton class="self-end" width="110px" />
          </div>

          <div v-if="is_API_loaded" class="box iframeBox relative col-span-2">
            <div class="flex items-center justify-between">
              <h2 class="text-xl font-medium text-gray-text_dark text-limit-1">
                {{ activatingTheme.stitle }}
                <span v-if="activatingTheme.title"> - </span>
                {{ activatingTheme.title }}
              </h2>
              <!-- <div class="flex items-center flex-shrink-0 space-x-1">
              <vs-button @click.stop="dropdownClick(99)" size="medium" border style="overflow: visible">
                <div class="flex items-center space-x-1">
                  <span class="font-medium">操作</span>
                  <svg-icon icon-class="triangle" className="primary" style="width: 0.7rem; height: 0.7rem"></svg-icon>
                </div>
                <transition name="fade">
                  <dropdown v-if="dropdownIndex === 99">
                    <div class="space-y-1">
                      <vs-button transparent size="large">重新命名</vs-button>
                      <vs-button transparent size="large">複製</vs-button>
                      <hr />
                      <vs-button transparent size="large" color="danger">刪除</vs-button>
                    </div>
                  </dropdown>
                </transition>
              </vs-button>
            </div> -->
            </div>
            <div ref="pc_iframe_wrap" class="pc_iframe_wrap">
              <iframe id="pc_iframe" scrolling="no" :src="$store.state.getWebData.param.wlink" class=""></iframe>
            </div>
            <div class="mobile_iframe_wrap" style="min-width: 230px">
              <img src="@/assets/images/iphone_mockup.png" class="w-full relative" />
              <div ref="mobile_iframe_div" class="mobile_iframe_div">
                <iframe id="mobile_iframe" scrolling="no" :src="$store.state.getWebData.param.wlink" class=""></iframe>
              </div>
            </div>
          </div>

          <div v-else class="box col-span-2">
            <skeleton height="300px" />
          </div>
        </section>
        <!-- <hr /> -->

        <!-- 已安裝的主題 -->
        <!-- <section v-if="is_API_loaded">
        <div class="flex items-center justify-between mb-4">
          <h1 class="title" style="margin: 0">已安裝的主題</h1>
          <vs-button size="large">瀏覽更多主題</vs-button>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-10">
          <div class="relative" v-for="(theme, index) in 6" :key="index">
            <div class="h-80 overflow-y-auto relative rounded-xl shadow-lg">
              <img src="@/assets/images/sample2.jpg" alt="" />
            </div>
            <div
              style="backdrop-filter: saturate(180%) blur(8px); background: rgba(255, 255, 255, 0.85)"
              class="absolute w-full bottom-0 p-4 space-y-2 z-10 rounded-b-xl"
            >
              <div class="flex items-center justify-between">
                <h2 class="text-base font-medium text-gray-text_dark text-limit-1">MY THEME</h2>
                <div class="flex items-center flex-shrink-0 space-x-1">
                  <vs-button @click.stop="dropdownClick(index + 1)" size="medium" border style="overflow: visible">
                    <div class="flex items-center space-x-1">
                      <span class="font-medium">操作</span>
                      <svg-icon icon-class="triangle" className="primary" style="width: 0.7rem; height: 0.7rem"></svg-icon>
                    </div>
                    <transition name="fade">
                      <dropdown v-if="dropdownIndex === index + 1">
                        <div class="space-y-1">
                          <vs-button transparent size="large">重新命名</vs-button>
                          <vs-button transparent size="large">複製</vs-button>
                          <hr />
                          <vs-button transparent size="large" color="danger">刪除</vs-button>
                        </div>
                      </dropdown>
                    </transition>
                  </vs-button>
                  <vs-button size="medium" transparent>
                    <div class="flex items-center">
                      <svg-icon class="" icon-class="eye_view" className="primary"></svg-icon>
                      <span class="font-medium">預覽</span>
                    </div>
                  </vs-button>
                </div>
              </div>
              <p class="text-limit-3 text-xs" style="max-height: fit-content">
                主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述主題描述
              </p>
            </div>
          </div>
        </div>
      </section> -->
        <!-- 骨架屏 -->
        <!-- <div v-else>
        <skeleton width="120px" height="25px" class="mb-4" />
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-10">
          <skeleton v-for="(item, index) in 3" :key="index" height="320px" />
        </div>
      </div> -->
      </main>
    </div>
  </div>
</template>

<script>
import dropdown from '@/components/dropdown'
export default {
  name: 'themes',
  components: {
    dropdown,
  },
  created() {
    this.loadInstalledThemes()
  },
  mounted() {
    // 監聽點擊，實現點擊dropdown之外的地方，關閉dropdown
    document.addEventListener('click', this.mouseClick)
    this.loadIframe()
  },
  // 銷毀自定義的監聽
  beforeDestroy() {
    document.removeEventListener('click', this.mouseClick)
  },
  data() {
    return {
      is_API_loaded: true,
      dropdownIndex: null,
      themes: [], // 所有主題(尚未安裝的)
      installedThemes: [], // 已安裝的主題
      activatingTheme: {},
    }
  },
  methods: {
    mouseClick(e) {
      this.dropdownIndex = null
    },
    dropdownClick(index) {
      // 如果是開著，就關閉
      if (this.dropdownIndex) {
        this.dropdownIndex = null
      } else {
        // 設定setTimeout 比mouseClick晚觸發
        setTimeout(() => {
          this.dropdownIndex = index
        }, 0)
      }
    },
    editTheme(id) {
      this.$router.push({
        name: 'edit-theme',
        params: {
          id,
        },
      })
    },
    // 2.3.1 佈景庫列表-讀取
    loadThemes() {
      this.$axios({
        url: 'front/pages/home/getBHomeList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: 1,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.themes = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.3.2 佈景列表-讀取
    loadInstalledThemes() {
      this.$axios({
        url: 'front/pages/home/getHomeList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.activatingTheme = res.data.Data.arr1[0]
          this.installedThemes = res.data.Data.arr2
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 讀取iframe
    loadIframe() {
      // 5秒後若還沒onload，關閉loading
      setTimeout(() => {
        if (loading_mobile.isVisible) loading_mobile.close()
        if (loading_pc.isVisible) loading_pc.close()
      }, 5000)

      // 手機版iframe loading
      const loading_mobile = this.$vs.loading({
        target: this.$refs.mobile_iframe_div,
        background: 'rgb(220,220,220)',
        scale: '1',
        type: 'scale',
        opacity: 0.6,
        color: '#fff',
      })

      let mobile_iframe = document.getElementById('mobile_iframe')

      mobile_iframe.onload = () => {
        loading_mobile.close()
      }

      // 電腦版iframe loading
      const loading_pc = this.$vs.loading({
        target: this.$refs.pc_iframe_wrap,
        background: 'rgb(220,220,220)',
        scale: '1',
        type: 'scale',
        opacity: 0.6,
        color: '#fff',
      })

      let pc_iframe = document.getElementById('pc_iframe')

      pc_iframe.onload = () => {
        loading_pc.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply font-medium text-gray-text_dark text-lg mb-4;
}

.box.iframeBox {
  height: calc(400px + 32px + 24px + 32px);
}

// 電腦iframe
.pc_iframe_wrap {
  height: 400px;
  @apply rounded-lg relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }

  #pc_iframe {
    width: 200%;
    height: 800px;
    transform: scale(0.5);
    transform-origin: 0 0;
    @apply rounded-xl shadow-xl border-2 relative;
  }

  ::v-deep .vs-loading {
    z-index: 0 !important;
  }
}

// 手機iframe
.mobile_iframe_wrap {
  @apply absolute right-0 -bottom-14 w-5/20 overflow-hidden;
  // z-index: 101;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }

  .mobile_iframe_div {
    overflow: hidden;
    position: absolute;
    top: 76px;
    left: 40px;
    width: 150px;
    height: 261px;

    #mobile_iframe {
      @apply absolute top-0 left-0;
      width: 304%;
      height: 400%;
      // width: 200%;
      // height: 400%;
      // top: 19%;
      // right: 39px;
      transform: scale(0.329, 0.315);
      transform-origin: top left;
    }
  }
}
</style>
